<template>
  <div class="do-page-login">
    <van-dialog
      v-model:show="showDialog"
      title="提示"
      class="do-com-dialog dialog-login"
      confirmButtonText="我知道了"
      confirmButtonColor="#0057FF"
    >
      <div class="tip">
        请先同意勾选<br />
        《用户服务协议》 和 《隐私协议》
      </div>
    </van-dialog>
    <div class="logo-wrap">
      <img :src="require('@/assets/images/my/logo-light.png')" />
    </div>
    <div class="title">Hi</div>
    <div class="hellow">欢迎使用客流易</div>
    <div class="tip">大数据客流·洞察工具</div>
    <div class="input-wrap">
      <div class="title">+86</div>
      <input
        type="number"
        @focus="phoneFocus"
        @blur="phoneBlur"
        v-model="phone"
        placeholder="请输入你的手机号码"
      />
    </div>
    <div class="radio-wrap">
      <van-checkbox icon-size="0.28rem" v-model="protocolRadio">
        &nbsp;&nbsp;我已阅读并同意
      </van-checkbox>
      <span
        class="radio-wrap-tip"
        style="margin-left: 0.08rem"
        @click="() => goWebPage(1)"
        >用户服务协议</span
      >、
      <span class="radio-wrap-tip" @click="() => goWebPage(2)">隐私协议</span>
    </div>
    <van-button
      :loading="loading"
      loading-type="spinner"
      @click="getAuthCode"
      class="login-btn"
      type="primary"
      >获取验证码</van-button
    >
    <!-- <div class="rest-login-wrap" id="restLogin">
      <div class="rest-login-tip">其他登录方式</div>
      <div style="display: flex">
        <div style="margin-right: 0.4rem" class="rest-login">
          <img :src="require('@/assets/images/my/weixin-bg.png')" />
        </div>
        <div class="rest-login">
          <img :src="require('@/assets/images/my/apple-bg.png')" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ref, getCurrentInstance } from "vue";
import { Toast } from "vant";
import { getAccountCaptcha } from "@/axios/user";

const { appContext } = getCurrentInstance();
const { $ComJs } = appContext.config.globalProperties; //全局属性解构
const isAndroid = $ComJs.dicernPhone() === 0;
const router = useRouter();
const protocolRadio = ref(false);
const showDialog = ref(false);
const phone = ref("");
const loading = ref(false);

// 跳转协议页面
const goWebPage = (type) => {
  const url =
    type === 1
      ? process.env.VUE_APP_USER_AGREEMENT_URL
      : process.env.VUE_APP_PRIVACY_AGREEMENT_URL;
  if (window.plus) {
    window.plus.runtime.openURL(url);
  } else {
    window.open(url);
  }
};

function phoneFocus() {
  // if (isAndroid) document.getElementById("restLogin").style.display = "none";
}
function phoneBlur() {
  // if (isAndroid) {
  //   setTimeout(() => {
  //     document.getElementById("restLogin").style.display = "block";
  //   }, 200);
  // }
}
//手机号码正则表达式验证
function checkPhone(phone) {
  if (!/^1[3456789]\d{9}$/.test(phone)) {
    return false;
  }
  return true;
}

async function getAuthCode() {
  if (protocolRadio.value === false) {
    showDialog.value = true;
    return;
  }
  if (!checkPhone(phone.value)) {
    Toast({
      position: "bottom",
      message: "请输入正确手机号码",
    });
    return;
  }
  loading.value = true;
  try {
    const res = await getAccountCaptcha({ phone: phone.value });
    loading.value = false;
    router.push("/auth-code/" + phone.value + "-login");
    return false;
  } catch (error) {
    console.log("error", error);
    if (error) {
      Toast({
        position: "bottom",
        message: error.msg || "服务端错误 ",
      });
    }
  }
  setTimeout(() => {
    loading.value = false;
  }, 300);
}
</script>

<style lang="scss">
.dialog-login {
  .van-dialog__content {
    text-align: center;
    font-size: 0.28rem;
    font-weight: 400;
    color: #969799;

    .tip {
      font-size: 0.28rem;
      font-weight: 400;
      color: #969799;
      margin-bottom: 0;
    }
  }
}
.do-page-login {
  @include fixedCom(0, 0, 0, 0);
  padding: 0 0.4rem;
  background: linear-gradient(180deg, #f0f5ff 0%, #ffffff 100%);
  display: flex;
  flex-flow: column;
  align-items: center;
  .rest-login {
    width: 0.84rem;
    height: 0.84rem;
    border-radius: 50%;
    background: #f5f8fc;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 0.84rem;
      width: 0.84rem;
    }
  }
  .rest-login-wrap {
    position: absolute;
    bottom: 1rem;
  }
  .rest-login-tip {
    font-size: 0.24rem;
    font-weight: 400;
    color: rgba(3, 5, 15, 0.45);
    margin-bottom: 0.4rem;
    text-align: center;
  }
  .login-btn {
    width: 100%;
    padding: 0.35rem 0;
    display: flex;
    justify-content: center;
    background: #0057ff;
    border-radius: 0.16rem;
    font-size: 0.3rem;
    font-weight: 500;
    color: #ffffff;
    margin-top: 0.48rem;
  }
  .radio-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.16rem;
    font-size: 0.24rem;
    font-weight: 400;
    color: rgba(3, 5, 15, 0.45);
    .van-checkbox__label {
      font-size: 0.24ren;
      font-weight: 400;
      color: rgba(3, 5, 15, 0.45);
      text-decoration: none;
      margin-left: 0;
      position: relative;
      top: 0.02rem;
    }
    .radio-wrap-tip {
      text-decoration: underline;
      position: relative;
    }
  }
  .logo-wrap {
    margin-top: 1.66rem;
    img {
      width: 2.16rem;
      height: 2.16rem;
    }
    margin-bottom: 0.2rem;
  }
  .title {
    display: flex;
    align-items: center;
    font-size: 0.42rem;
    font-weight: 500;
    color: #03050f;
    margin-bottom: 0.08rem;
  }

  .hellow {
    font-size: 0.42rem;
    font-weight: 500;
    color: #03050f;
    margin-bottom: 0.16rem;
  }

  .tip {
    font-size: 0.28rem;
    font-weight: 400;
    color: rgba(3, 5, 15, 0.25);
    margin-bottom: 0.8rem;
  }
  .input-wrap {
    padding: 0.28rem 0.24rem;
    background: rgba(2, 4, 15, 0.04);
    border-radius: 0.16rem;
    height: 0.96rem;
    display: flex;
    align-items: center;
    width: 100%;
    .title {
      font-size: 0.28rem;
      font-weight: 400;
      margin-bottom: 0;
      color: #03050f;
      @include border-right(1px, rgba(3, 5, 15, 0.25));
      padding-right: 0.24rem;
    }
    input {
      width: 100%;
      margin-left: 0.24rem;
      font-size: 0.28rem;
      font-weight: 400;
      color: rgba(3, 5, 15, 0.85);
      &::-webkit-input-placeholder {
        font-size: 0.28rem;
        font-weight: 400;
        color: rgba(3, 5, 15, 0.25);
      }
    }
  }
}
</style>
